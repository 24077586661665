.message {
  &__container {
    position: fixed;
    bottom: 0;
    right: 20px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    z-index: 1100;
  }
}
