.social {
  align-items: center;

  &__list {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0 -10px;
    padding: 0;
  }

  &__title {
    margin-bottom: 0;
  }

  &__item {
    margin: 0 10px;
  }

  &__link {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
