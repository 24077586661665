@import "./spinner.scss";
@import "./message.scss";
@import "./transition.scss";
@import "./social.scss";

.padding-section {
  padding-top: 50px;
  @media (max-width: 991px) {
    padding-top: 20px;
  }
}

main {
  padding-bottom: 15px;
  margin-bottom: auto;
}

.cursor-pointer {
  cursor: pointer;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (max-width: 991px) {
  html {
    font-size: 14px;
  }
}

.poker-card {
  flex: calc(20% - 10px) 0 1;
  max-width: calc(20% - 10px);
  margin: 5px 5px;
  position: relative;
  list-style: none;
}

.poker-card-back {
  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
}
ul {
  padding-left: 0;
}

.poker-card-front {
  max-width: 100%;
  height: auto;
  object-fit: contain;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@media (max-width: 992px) {
  .card-body {
    padding: 5px !important;
    height: 100%;
  }

  .card-title {
    font-size: 22px;
  }
}

@media (max-width: 500px) {
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card-title {
    margin-bottom: 5px !important;
    font-size: 18px;
  }
}
