@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner div {
  box-sizing: border-box !important;
}

.spinner > div {
  // position: absolute;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  border: 16px solid #000;
  border-color: #e15b64 transparent #e15b64 transparent;
  animation: spinner 1s linear infinite;
}

.spinner > div:nth-child(2),
.spinner > div:nth-child(4) {
  width: 108px;
  height: 108px;
  top: 46px;
  left: 46px;
  animation: spinner 1s linear infinite reverse;
}

.spinner > div:nth-child(2) {
  border-color: transparent #f8b26a transparent #f8b26a;
}

.spinner > div:nth-child(3) {
  border-color: transparent;
}

.spinner > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.spinner > div:nth-child(3) div:before,
.spinner > div:nth-child(3) div:after {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 48px;
  background: #e15b64;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #e15b64;
}

.spinner > div:nth-child(3) div:after {
  left: -16px;
  top: 48px;
  box-shadow: 128px 0 0 0 #e15b64;
}

.spinner > div:nth-child(4) {
  border-color: transparent;
}

.spinner > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.spinner > div:nth-child(4) div:before,
.spinner > div:nth-child(4) div:after {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 30px;
  background: #f8b26a;
  border-radius: 50%;
  box-shadow: 0 92px 0 0 #f8b26a;
}

.spinner > div:nth-child(4) div:after {
  left: -16px;
  top: 30px;
  box-shadow: 92px 0 0 0 #f8b26a;
}

.loadingio-spinner-double-ring-roezpun3pzm {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}

.spinner {
  width: auto;
  height: auto;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.spinner div {
  box-sizing: content-box;
}

.menu-transition {
  @media (max-width: 992px) {
    height: 0px;
    transition: height 0.35s ease;
    overflow: hidden;
  }
}

.list-transition {
  height: 0px;
  transition: height 0.35s ease;
  overflow: hidden;
}
